import mutations from '../mutation-types';
import actions from '../action-types'
import http from '../../plugins/http'
import router from '../../router/router'
const store = {
   namespaced: true,

   state: {
      title: 'Sleep Well',
      editMode: false,
      categories: [],
      hiddenCategories: [],
      advices: [],
      subcategories: [],
      hiddenSubcategories: [],
      currentSubcategories: {},
      subcategory: {},
      category: {},
      fontSize: '',
      isDataLoading: false,
      search: '',
      play: false,
      idOfPlayingAdvice: '',
      advicesHidden: [],
      isModalOpen: false,
      editItems: [],
      unvisible: [],
      isCategoriesLoaded: false,
      isSerach: false
   },

   getters: {
      getCategories(state) {
         if (state.search) {
            let filteredByAdvices = state.categories.filter(item => Boolean(item?.text?.toLowerCase().match(state.search)))
            let filteredCategories = state.categories.filter(item => filteredByAdvices.some(advice => advice.parent === item.code && !item.parent))
            return filteredCategories;
         }
         return state.categories.filter(item => !item.parent && item.title);
      },

      getCategoriesFilterSearch(state) {
         return state.categories.filter(item => Boolean(item?.text?.toLowerCase().match(state.search)))
      },

      getHiddenCategories: (state) => state.hiddenCategories.filter(item => !item.parent && item.title),

      getCategory: (state) => state.category,

      getSubcategories: (state) => {
         if (state.search) {
            let subcategoriesChilds = state.categories.filter(({ parent, text }) => state.subcategories.some(({ code }) => {
               return parent === code && Boolean(text?.toLowerCase().match(state.search))
            }))
            let filteredSubcategories = state.categories.filter(({ code }) => subcategoriesChilds.some(({ parent }) => code === parent))
            return filteredSubcategories
         }
         return state.subcategories
      },

      getHiddenSubcategories: (state) => state.hiddenSubcategories,

      getAdvices: (state) => {
         if (state.search) {
            return state.advices.filter(({ text }) => Boolean(text.toLowerCase().match(state.search)))
         }
         return state.advices
      },

      getHiddenAdvices: (state) => state.advicesHidden,

      getTitle: (state) => state.title,

      getEditMode: (state) => state.editMode,

      getCurrentFontSize: (state) => state.fontSize,

      getLoading: (state) => state.isDataLoading,

      getPlay: (state) => state.play,

      getIdOfPlayingAdvice: (state) => state.idOfPlayingAdvice,

      getIsModalOpen: (state) => state.isModalOpen,

      getAdvicesToPlay: (state) => state.advices,

      getEditItems: (state) => state.editItems,

      getUnvisible: (state) => state.unvisible,

      getIsCategoriesLoaded: (state) => state.isCategoriesLoaded,

      getIsSearch: (state) => state.isSerach

   },

   mutations: {
      [mutations.FIND_CATEGORY](state, id) {
         state.category = state.categories.filter(item => item._id == id)[0]
      },

      [mutations.FIND_SUBCATEGORIES](state, parentCode) {
         state.subcategories = state.categories.filter(item => item.parent === parentCode && !item.text)
         state.hiddenSubcategories = state.hiddenCategories.filter(item => item.parent === parentCode && !item.text)
      },

      [mutations.FIND_ADVICES](state, parentCode = null) {
         if (!parentCode) {
            state.advices = state.categories.filter(item => item.text)
         } else {
            state.advices = state.categories.filter(item => item.parent === parentCode && item.text)
            state.advicesHidden = state.hiddenCategories.filter(item => item.parent === parentCode && item.text)
         }
      },

      [mutations.UPDATE_TITLE](state, title) {
         state.title = title
      },

      [mutations.UPDATE_EDITE_MODE](state, isEnable) {
         state.editMode = isEnable
      },

      [mutations.UPDATE_THEME](state, theme) {
         state.theme = theme
      },

      [mutations.UPDATE_FONT_SIZE](state, font) {
         state.fontSize = font
      },

      [mutations.UPDATE_CATEGORIES](state, data) {
         state.categories = data
      },

      [mutations.UPDATE_HIDDEN_CATEGORIES](state, data) {
         state.hiddenCategories = data
      },

      [mutations.UPDATE_LOADING](state, data) {
         state.isDataLoading = data
      },

      [mutations.UPDATE_SEARCH](state, data) {
         if (data) {
            state.isSerach = true
         } else {
            state.isSerach = false
         }
         state.search = data
      },

      [mutations.UPDATE_PLAY](state, data) {
         state.play = data;
      },

      [mutations.SET_ID_OF_PLAYING_ADVICE](state, id) {
         state.idOfPlayingAdvice = id
      },

      [mutations.UPDATE_UNVISIBLE](state, ids) {
         let clear = [...ids, ...state.unvisible]
         state.unvisible = [...new Set(clear)]
      },

      [mutations.REMOVE_UNVISIBLE](state, ids) {
         let clearIds = [...state.unvisible];
         for (let id of ids) {
            if (clearIds.includes(id)) {
               clearIds.splice(clearIds.indexOf(id), 1)
            }
         }
         state.unvisible = clearIds
      },

      [mutations.SET_IS_MODAL_OPEN](state, payload) {
         state.isModalOpen = payload
      },

      [mutations.UPDATE_EDIT_ITEMS](state, payload) {
         state.editItems = [...payload]
      },

      [mutations.UPDATE_UNVISIBLE](state, payload) {
         state.unvisible = payload
      },

      updateCategoriesLoaded(state, payload) {
         state.isCategoriesLoaded = payload
      }
   },

   actions: {
      async[actions.FETCH]({ commit, dispatch }) {
         commit(mutations.UPDATE_LOADING, true)
         commit('updateCategoriesLoaded', false)

         let data = await http.post('resources/categories');
         commit(mutations.UPDATE_CATEGORIES, data.data.visible)
         commit(mutations.UPDATE_HIDDEN_CATEGORIES, data.data.unvisible)
         let idsUnvisible = data.data.unvisible.map(item => item._id)
         dispatch('updateUnvisible', idsUnvisible)

         commit('updateCategoriesLoaded', true)
         commit(mutations.UPDATE_LOADING, false)
      },

      updateUnvisible({ commit }, unvisible) {
         commit(mutations.UPDATE_UNVISIBLE, unvisible)
         commit(`profile/${mutations.UPDATE_PROFILE}`, { unvisible: unvisible }, { root: true })
      },

      updateData({ commit, state }) {
         if (router.history.current.name === 'app-main-subcategory') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);

         }
         else if (router.history.current.name === 'app-main-category') {
            commit(mutations.FIND_CATEGORY, router.history.current.params.id);
            commit(mutations.UPDATE_TITLE, state.category.title);
            commit(mutations.FIND_ADVICES, state.category.code);
            commit(mutations.FIND_SUBCATEGORIES, state.category.code);
         }
         else {
            commit(mutations.UPDATE_TITLE, 'Sleep Well');
            commit(mutations.FIND_ADVICES)
         }
      }
   }
};

export default store;
