export const getQuestions = function () {
	return new Promise((resolve, reject) => {
		console.log("Getting Quesions");
		const result = [
			{
				questionNumber: 1,
				questionTitle: "Здесь заголовок вопроса",
				managerText: "Здесь manager text вопроса",
				answers: [
					{
						id: 1,
						value: 'No'
					},
					{
						id: 2,
						value: 'Yes'
					},
				]
			},

			{
				questionNumber: 2,
				questionTitle: "Здесь заголовок второго вопроса",
				managerText: "Здесь manager второго вопроса",
				answers: [
					{
						id: 3,
						value: 'No'
					},
					{
						id: 4,
						value: 'Yes'
					},
				]
			},

			{
				questionNumber: 3,
				questionTitle: "Здесь заголовок 3-го вопроса",
				managerText: "Здесь manager 3-го вопроса",
				answers: [
					{
						id: 5,
						value: 'No'
					},
					{
						id: 6,
						value: 'Yes'
					},
				]
			},
			{
				questionNumber: 4,
				questionTitle: "Здесь заголовок 4-го вопроса",
				managerText: "Здесь manager 4-го вопроса",
				answers: [
					{
						id: 7,
						value: 'No'
					},
					{
						id: 8,
						value: 'Yes'
					},
				]
			},

		]

		if (result) {
			resolve(result);
		}
		else {
			reject('no data');
		}
	});
}

export const getCategories = function () {
	return new Promise((resolve, reject) => {
		console.log("Getting Categories data");
		const result = [
			{
				id: 1,
				title: 'Как легче проснутся утром'
			},
			{
				id: 2,
				title: 'Как расслабится вечером и заснуть'
			},
			{
				id: 3,
				title: 'Релаксационные техники перед сном'
			},
			{
				id: 4,
				title: 'Массаж'
			},
			{
				id: 5,
				title: 'Техники релаксации на весь день'
			},
			{
				id: 6,
				title: 'Гигиена спальни'
			},
		]

		if (result) {
			resolve(result);
		}
		else {
			reject('no data');
		}
	});
}

export const getResults = function (type) {
	return new Promise((resolve, reject) => {
		try {
			const results =
				[
					{
						title: 'Вам подойдет программа SMALL',
						description: 'Готовый вкусный рацион со средней калорийностью от 1700 до 2000 ккал и с 6 приемами пищи на каждый день. Только лучшие блюда и натуральные ингредиенты'
					},
					{
						title: 'Вам подойдет программа MEDIUM',
						description: 'Готовый вкусный рацион со средней калорийностью от 2000 до 2300 ккал и 6 приемами пищи каждый день и регулярные занятиям спортом 3-4 раза в неделю. Только лучшие блюда из натуральных ингредиентов и программа тренировок.'
					},
					{
						title: 'Вам подойдет программа LARGE',
						description: 'Готовый вкусный рацион со средней калорийностью от 2300 до 2700 ккал и 7 приемами пищи каждый день и интенсивные занятиям спортом не меньше 5 раза в неделю. Только лучшие блюда из натуральных ингредиентов и программа тренировок.'
					},
				]

			resolve(results[type]);
		}
		catch {
			reject('no data');
		}
	});
}