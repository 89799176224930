import mutations from '../mutation-types';
import actions from '../action-types';
import http from '../../plugins/http'

import { getResults } from '../../api';

const store = {
   namespaced: true,
   state: {
      discount: 0,
      email: '',
      phone: '',
      name: '',
      results: {
         title: '',
         description: '',
      },
      first_name: '',
      last_name: '',
      locale: '',
      img: '',
      userAnswers: [],
      questions: [],
      currentDiscount: 0,
      currentStep: 0,
      discountPerStep: 0,
      prevStep: 0
   },

   getters: {
      getDiscount(state) {
         return state.discount;
      },

      getCurrentDiscount(state) {
         return state.currentDiscount;
      },

      getCurrentStep(state) {
         return state.currentStep;
      },

      getName(state) {
         return state.name;
      },

      getResults(state) {
         return state.results;
      },

      getAnswers(state) {
         return state.answers;
      },

      getUserAnswers(state) {
         return state.userAnswers;
      },

      getAdminInfo(state) {
         return state.admin
      },

      getDiscountPerStep(state) {
         return state.discountPerStep
      },

      getPrevStep(state) {
         return state.prevStep
      },

      getQuestions(state) {
         return state.questions
      }
   },

   mutations: {
      [mutations.UPDATE_DISCOUNT](state, discount) {
         state.discount = discount;
      },

      [mutations.CHANGE_NAME](state, name) {
         state.name = name;
      },

      [mutations.CHANGE_EMAIL](state, email) {
         state.email = email;
      },

      [mutations.CHANGE_PHONE](state, phone) {
         state.phone = phone;
      },

      [mutations.UPDATE_LOCALE](state, results) {
         state.locale = results;
      },

      [mutations.UPDATE_IMG](state, results) {
         state.img = results;
      },

      [mutations.UPDATE_LASTNAME](state, results) {
         state.last_name = results;
      },

      [mutations.UPDATE_FIRSTNAME](state, results) {
         state.first_name = results;
      },

      [mutations.UPDATE_RESULTS](state, results) {
         state.results = results;
      },

      [mutations.SET_USER_ANSWERS](state, answers) {
         state.userAnswers = answers;
      },

      UPDATE_ADMIN(state, data) {
         state.admin = data
      },

      [mutations.UPDATE_DISCOUNT_PER_STEP](state, data) {
         state.discountPerStep = data
      },

      UPDATE_QUIZ(state, data) {
         state.questions = data
      },

      UPDATE_CURRENT_DISCOUNT(state, data) {
         state.currentDiscount = data
      },

      UPDATE_CURRENT_STEP(state, data) {
         state.prevStep = state.currentStep
         state.currentStep = data
      }
   },

   actions: {
      [actions.LOAD_RESULTS]({ commit }, type) {
         getResults(type)
            .then(data => {
               commit(mutations.UPDATE_RESULTS, data);
            })
      },

      async fetchQuiz({ commit }) {
         let data = await http.post('/resources/questions');
         commit('UPDATE_QUIZ', data.data)
      },

      async fetchDiscount({ commit }) {
         let data = await http.post('/resources/discount');
         commit(mutations.UPDATE_DISCOUNT, data.data.discount[0].discount)
      },

      async updateDiscount({ state, commit }) {
         commit(mutations.UPDATE_DISCOUNT_PER_STEP, 0)
         if (state.prevStep < state.currentStep) {
            commit(mutations.UPDATE_DISCOUNT_PER_STEP, Math.round(state.discount / state.questions.length) * (state.currentStep - state.prevStep))
            commit('UPDATE_CURRENT_DISCOUNT', state.currentDiscount + state.discountPerStep)
         } else {
            commit(mutations.UPDATE_DISCOUNT_PER_STEP, Math.round(state.discount / state.questions.length))
            commit('UPDATE_CURRENT_DISCOUNT', state.currentDiscount - state.discountPerStep)
         }
      }
   },
};

export default store;
