import http from '../../plugins/http'

export async function authAdmin({ next }) {
   if (!sessionStorage.isAuth) {
      try {
         let data = await http.post('/auth/isAuthAdmin')
         if (data.status === 200) {
            sessionStorage.isAuth = true
         }
      }
      catch {
         return next({
            name: 'admin-login'
         })
      }
   }
   return next()
}

/* export async function authUser({ next }) {
   if (!sessionStorage.isAuthUser) {
      try {
         let data = await http.post('/auth/isAuthUser')
         if (data.status === 200) {
            sessionStorage.isAuthUser = true
         }
      }
      catch (e) {
         alert('Authorize yourself please');
         window.location.replace(location.origin.replace('app.', ''))
      }
   }
   return next()
} */