<template>
	<div class="wrapper" ref="wrapper">
		<transition name="fade">
			<router-view />
		</transition>
	</div>
</template>

<script>
export default {};
</script>

<style>
@import '../node_modules/sw-ui-kit/dist/styles.css';
</style>

<style lang="sass">
@import './sass/app'

.fade-enter-active, .fade-leave-active
   transition: opacity .3s

.fade-enter, .fade-leave-to
   opacity: 0

.wrapper,#app
   height: 100%
</style>
