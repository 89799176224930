import mutations from "../mutation-types"
import http from '../../plugins/http.js'

const store = {
   namespaced: true,
   state: {
      settings: {},
      resources: {},
      loading: true,
      menuItems: [
         { text: 'Dashboard', path: 'home' },
         { text: 'Admin', path: 'admin' },
         { text: 'User', path: 'user' },
         { text: 'Discount', path: 'discount' },
         { text: 'Questions', path: 'questions' },
         { text: 'Categories', path: 'categories' },
         { text: 'Subscriptions', path: 'subscribtion' },
         { text: 'Coupon', path: 'coupon' },
      ],
   },

   getters: {
      getResources: (state) => {
         return state.resources
      },

      getSettings: (state) => {
         return state.settings
      },

      getLoading: (state) => {
         return state.loading
      },

      getMenuItems: (state) => state.menuItems
   },

   mutations: {
      [mutations.UPDATE_ADMIN_RESOURCES](state, data) {
         state.resources = data
      },

      [mutations.UPDATE_ADMIN_SETTINGS](state, data) {
         state.settings = data
      },

      [mutations.UPDATE_LOADING](state, data) {
         state.loading = data
      }
   },

   actions:{
      async loadResources({commit}) {
            const response = await http.post('/admin/resources');
            commit(`${mutations.UPDATE_ADMIN_RESOURCES}`, response.data.resources);
            commit(`${mutations.UPDATE_ADMIN_SETTINGS}`, response.data.settings);
            commit(`${mutations.UPDATE_LOADING}`, false);
      },
   }
}


export default store