import Vue from 'vue';
import App from './App.vue';
import router from './router/router.js';
import store from './store/store.js';
import http from './plugins/http.js';
import { i18n } from './i18n';
import './sass/vuetiftyColor.scss';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.use(i18n);

//! this is a crutch for the phone number input. Used in src/views/TheRegistration component
Vue.directive('phoneNumber', {
   bind: function (el) {
     el.addEventListener('input', function (event) {
       let value = event.target.value
       value = value.replace(/\D/g, '')
       value = '+' + value
       event.target.value = value
     })
   }
 })
//!end crutch
new Vue({
   router,
   store,
   vuetify,
   i18n,
   render: h => h(App),
}).$mount('#app');
