import mutations from '../mutation-types';
import http from '../../plugins/http'
import actions from '../action-types'

const store = {
   namespaced: true,

   state: {
      profile: {
         discount: 0,
         email: "",
         first_name: "",
         img: "",
         last_name: "",
         locale: "",
         phone: "",
         subscription: "",
         subscription_time: "",
         themeStyles: "",
      },

      profileItemEditing: false
   },

   getters: {
      getProfile: (state) => state.profile,

      getProfileSubscription: (state) => state.profile.subscription,

      getProfileItemEditing: (state) => state.profileItemEditing,
   },

   mutations: {
      [mutations.UPDATE_PROFILE](state, data) {
         for (let key in data) {
            state.profile[key] = data[key]
         }
      },

      [mutations.SET_PROFILE](state, data) {
         state.profile = data;
      },

      [mutations.PROFILE_ITEM_EDITING](state, data) {
         state.profileItemEditing = data
      }
   },

   actions: {
      async updateProfile({ state, commit }) {
         commit(`advice/${mutations.UPDATE_LOADING}`, true, { root: true })
         await http.post('resources/put_profile', state.profile);
         commit(`advice/${mutations.UPDATE_LOADING}`, false, { root: true })

      },

      async [actions.FETCH]({ commit }) {
         commit(`advice/${mutations.UPDATE_LOADING}`, true, { root: true })
         let data = await http.post('resources/profile');
         commit(mutations.SET_PROFILE, data.data);
         commit(`advice/${mutations.UPDATE_LOADING}`, false, { root: true })
      }
   }
};

export default store;
