import Vue from 'vue';
import Vuex from 'vuex';
import quiz from './modules/quiz';
import advice from './modules/advice';
import profile from './modules/profile';
import admin from './modules/admin';

import mutations from './mutation-types'

Vue.use(Vuex);

const store = new Vuex.Store({
	strict: true,
	namespaced: true,
	state: {
		discount: 0,
		email: '',
		phone: '',
		name: '',
		results: {
			title: '',
			description: '',
		},
		userAnswers: [],
	},

	getters: {
		getDiscount(state) {
			return state.discount;
		},

		getName(state) {
			return state.name;
		},

		getResults(state) {
			return state.results;
		},

		getAnswers(state) {
			return state.answers;
		},
	},
	modules: {
		quiz,
		advice,
		profile,
		admin
	},
	actions: {
		bindNamespaces({ commit }, { _modulesNamespaceMap }) {
			Object.entries(_modulesNamespaceMap).forEach(([namespace, module]) => {
				commit('bindNamespace', { module, namespace });
			});
		},

	},
	mutations: {
		bindNamespace(_, { module, namespace }) {
			Vue.set(module.state, '_namespace', namespace);
		},

		[mutations.UPDATE_AUTH_ADMIN](state, value) {
			state.adminAuth = value
		}
	},
});
export default store;
